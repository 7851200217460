import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import AustinEvents from "../components/AustinEvents";

const allRecommendations = require("./recommendations.json");
const slicedRecommendations = allRecommendations.slice(0, 4);

const allBattles = require("./battles.json");
const slicedBattles = allBattles.slice(0, 5);

const IndexPage = () => {
  return (
    <>
      <Head
        title="Welcome to Austin City Taco"
        description="Home to the best recommendations & hand-picked choices for authentic mexican tacos in Austin Texas, where we match up taco specialties with our Taco Battles."
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-none-sidebar">
            <div className="primary-wrapper is-homepage">
              <section className="promo promo-top">
                <div className="montage">
                  <img src="/font-montage.png" className="montage" />
                </div>
                <div className="contents">
                  <div className="top">
                    <h1> Welcome to Austin City Tacos</h1>
                    <div className="sassy">
                      Follow us on our never-ending journey to find Austin's
                      ultimate champion taco. Let's be honest, we are all tired
                      of our Aunt's same old facebook reviews about how the tea
                      was too sweet. So let's dive right in, as we break down by
                      person reviews and compare by restaurants.
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="left">
                      <div className="toper">
                        <img src="/front-taco.png" className="front-taco" />
                        <h3 className="header"> Recommendations</h3>
                      </div>
                      <div className="desc">
                        Our <a href="/recommendations/">recommendations</a> of
                        local tacos with in-depth reviews of Austin's best spots
                        based on speciality.
                      </div>
                      <div className="recent">Recent Recommendations</div>
                      <div>
                        {slicedRecommendations.map((node, i) => {
                          return (
                            <div className="sample-list" key={i}>
                              <div className="title">
                                <a href={node.link}>
                                  <i className="fa-solid fa-magnifying-glass-location"></i>
                                  {node.title}
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="right">
                      <div className="toper">
                        <img src="/front-gloves.png" className="front-gloves" />
                        <h3 className="header">Taco Battles</h3>
                      </div>
                      <div className="desc">
                        A no-holds-barred <a href="/taco-battles/">battle</a> of
                        various types of tacos & stack up against each other
                        based on flavor and authenticity.
                      </div>
                      <div className="recent">Recent Battles</div>
                      <div>
                        {slicedBattles.map((node, i) => {
                          return (
                            <div className="sample-list" key={i}>
                              <div className="title">
                                <a href={node.link}>
                                  <i className="fa-solid fa-chess-knight"></i>
                                  {node.title}
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="promo-austin-events">
                <AustinEvents></AustinEvents>
              </section>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
